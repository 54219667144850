import { Heading, HeadingProps } from '@chakra-ui/react'
import type { VFC } from 'react'

export type Heading1ViewProps = {} & Heading1Props

export const Heading1View: VFC<Heading1ViewProps> = ({ title, _props }) => {
    return (
        <>
            <Heading
                data-testid="heading1"
                as="h1"
                fontFamily="'DIN Condensed W05 Demi Bold'"
                fontSize={{ base: '32px', md: '40px' }}
                fontWeight={{ base: 'bold' }}
                lineHeight={{ base: 'none' }}
                {..._props}>
                {title}
            </Heading>
        </>
    )
}

export type Heading1Props = {
    title: string
    _props?: HeadingProps
} & HeadingProps

export const Heading1: VFC<Heading1Props> = (props) => {
    return <Heading1View {...props} />
}
